<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('客户设备管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['equipment/add']" size="small">{{$t('新增设备')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <div class="search">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input :placeholder="$t('请输入关键字')" v-model="name" clearable class="input-with-select"></el-input>
          </el-col>
          <el-col :span="6">
            <el-select v-model="ScId" clearable @change="linkageID(ScId)" :placeholder="$t('请选择客户')">
                <el-option
                  v-for="item in ocList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="SrId" clearable :placeholder="$t('请选择区域')">
                <el-option
                  v-for="item in orList"
                  :key="item.id"
                  :label="item.rname"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="SeId" clearable :placeholder="$t('请选择设备类型')">
                <el-option
                  v-for="item in EmList"
                  :key="item.id"
                  :label="item.model_name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
          </el-col>
        </el-row>

      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column label="ID" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('客户名称')" prop="kehu_name" width="200">
        </el-table-column>
        <el-table-column :label="$t('归属区域')" prop="rname" width="300">
        </el-table-column>
        <el-table-column :label="$t('设备名称')" prop="equipment_name" width="200">
        </el-table-column>
        <el-table-column :label="$t('设备类型')" prop="model_name" width="150">
        </el-table-column>
        <el-table-column :label="$t('物联网卡号')" prop="wlw_cardNo" width="150">
        </el-table-column>
        <el-table-column :label="$t('录入时间')" prop="created_at" width="200">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="getDetail(scope)" v-if="user_role['equipment/detail']">{{$t('查看详情')}}</el-button>
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['equipment/edit']" size="small">{{$t('修改产品')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['equipment/delete']" size="small">{{$t('删除产品')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('添加产品')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" label-position="right" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('设备ID')" required prop="equipment_id" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.equipment_id" clearable></el-input>
            </el-form-item>
          </el-col><!-- /产品ID -->
          <el-col :span="12">
            <el-form-item :label="$t('产品名称')" required prop="equipment_name" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.equipment_name" clearable></el-input>
            </el-form-item>
          </el-col><!-- /产品名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品类型')" required prop="em_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.em_id" clearable :placeholder="$t('请选择')">
                  <el-option
                    v-for="item in EmList"
                    :key="item.id"
                    :label="item.model_name"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col><!-- /设备类型 -->
          <el-col :span="12">
            <el-form-item :label="$t('所属客户')" required prop="oc_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.oc_id" clearable :placeholder="$t('请选择')">
                  <el-option
                    v-for="item in ocList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col><!-- /客户id -->
          <el-col :span="12">
            <el-form-item :label="$t('归属区域')" required prop="or_id">
              <el-select v-model="addform.or_id" clearable :placeholder="$t('请选择')">
                  <el-option
                    v-for="item in orList"
                    :key="item.id"
                    :label="item.rname"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col><!-- /区域id -->
          <!-- <el-col :span="12">
            <el-form-item label="质保期" required prop="warranty_time">
              <el-date-picker
                    clearable
                    v-model="addform.warranty_time"
                    type="datetimerange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    >
                  </el-date-picker>
            </el-form-item>
          </el-col> --><!-- /质保时间 -->
          <el-col :span="12">
            <el-form-item :label="$t('物联网卡号')" prop="wlw_cardNo">
              <el-input v-model="addform.wlw_cardNo" clearable></el-input>
            </el-form-item>
          </el-col><!-- /物联网卡号 -->
          <el-col :span="12">
            <el-form-item :label="$t('出厂编号')" prop="wlw_factoryNo">
              <el-input v-model="addform.wlw_factoryNo" clearable></el-input>
            </el-form-item>
          </el-col><!-- /出厂编号 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="addform.remarks"></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改产品')" :visible.sync="editAdmin" width="80%">
      <el-row :gutter="20">
       <el-form :model="editform" label-position="right" label-width="100px" ref="editform">
         <el-col :span="12">
           <el-form-item :label="$t('设备ID')" required prop="equipment_id"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.equipment_id" clearable></el-input>
           </el-form-item>
         </el-col><!-- /设备ID -->
         <el-col :span="12">
           <el-form-item :label="$t('设备名称')" required prop="equipment_name" :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.equipment_name" clearable></el-input>
           </el-form-item>
         </el-col><!-- /设备名称 -->
         <el-col :span="12">
           <el-form-item :label="$t('设备类型')" required prop="em_id" :rules="[{ required: true, message: $t('不能为空')}]">
             <el-select v-model="editform.em_id" clearable :placeholder="$t('请选择')">
                 <el-option
                   v-for="item in EmList"
                   :key="item.id"
                   :label="item.model_name"
                   :value="item.id">
                 </el-option>
               </el-select>
           </el-form-item>
         </el-col><!-- /设备类型 -->
         <el-col :span="12">
           <el-form-item :label="$t('所属客户')" required prop="oc_id" :rules="[{ required: true, message: $t('不能为空')}]">
             <el-select v-model="editform.oc_id" clearable :placeholder="$t('请选择')">
                 <el-option
                   v-for="item in ocList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
                 </el-option>
               </el-select>
           </el-form-item>
         </el-col><!-- /客户id -->
         <el-col :span="12">
           <el-form-item :label="$t('归属区域')" required prop="or_id">
               <el-select v-model="editform.or_id" clearable :placeholder="$t('请选择')">
                   <el-option
                     v-for="item in orList"
                     :key="item.id"
                     :label="item.rname"
                     :value="item.id">
                   </el-option>
                 </el-select>
           </el-form-item>
         </el-col><!-- /区域id -->
         <!-- <el-col :span="12">
           <el-form-item label="质保期" required prop="warranty_time">
             <el-date-picker
                   v-model="editform.warranty_time"
                   type="datetimerange"
                   align="right"
                   range-separator="至"
                   start-placeholder="开始日期"
                   end-placeholder="结束日期"
                   format="yyyy-MM-dd hh:mm:ss"
                   value-format="yyyy-MM-dd hh:mm:ss"
                   >
                 </el-date-picker>
           </el-form-item>
         </el-col> --><!-- /质保时间 -->
         <el-col :span="12">
           <el-form-item :label="$t('物联网卡号')" prop="wlw_cardNo">
             <el-input v-model="editform.wlw_cardNo" clearable></el-input>
           </el-form-item>
         </el-col><!-- /物联网卡号 -->
         <el-col :span="12">
           <el-form-item :label="$t('出厂编号')" prop="wlw_factoryNo">
             <el-input v-model="editform.wlw_factoryNo" clearable></el-input>
           </el-form-item>
         </el-col><!-- /出厂编号 -->
         <el-col :span="24">
           <el-form-item :label="$t('备注信息')" prop="remarks">
             <el-input v-model="editform.remarks"></el-input>
           </el-form-item>
         </el-col><!-- /备注 -->
       </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('产品详情')" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form v-model="DetailForm" label-position="right" label-width="100px" ref="DetailForm">
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" prop="kehu_name">
              <el-input v-model="DetailForm.kehu_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品名称')" prop="equipment_name">
              <el-input v-model="DetailForm.equipment_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品类型')" prop="model_name">
              <el-input v-model="DetailForm.model_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品类型id -->
          <el-col :span="12">
            <el-form-item :label="$t('质保到期时间')" prop="warranty_end_time">
              <el-input v-model="DetailForm.warranty_end_time" disabled></el-input>
            </el-form-item>
          </el-col><!-- /质保到期时间 -->
          <el-col :span="12">
            <el-form-item :label="$t('产品功率')" prop="power">
              <el-input v-model="DetailForm.power" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品功率 -->
          <el-col :span="12">
            <el-form-item :label="$t('区域名称')" prop="rname">
              <el-input v-model="DetailForm.rname" disabled></el-input>
            </el-form-item>
          </el-col><!-- /区域名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('物联网卡号')" prop="wlw_cardNo">
              <el-input v-model="DetailForm.wlw_cardNo" disabled></el-input>
            </el-form-item>
          </el-col><!-- /物联网卡号 -->
          <el-col :span="12">
            <el-form-item :label="$t('出厂编号')" prop="wlw_factoryNo">
              <el-input v-model="DetailForm.wlw_factoryNo" disabled></el-input>
            </el-form-item>
          </el-col><!-- /出厂编号 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="DetailForm.remarks" disabled></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">{{$t('关闭')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'product',
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      UntreatedData: [],
      name: '',
      ScId: '',
      SrId: '',
      SeId: '',
      addform: {
        equipment_id: '',
        equipment_name: '',
        em_id: '',
        oc_id: '',
        or_id: '',
        // warranty_time: '',
        wlw_cardNo: '',
        wlw_factoryNo: '',
        remarks: ''
      },
      editform: {
        id: '',
        equipment_id: '',
        equipment_name: '',
        em_id: '',
        oc_id: '',
        or_id: '',
        // warranty_time: '',
        wlw_cardNo: '',
        wlw_factoryNo: '',
        remarks: ''
      },
      DetailForm: {
        id: '',
        oc_id: '',
        or_id: '',
        equipment_id: '',
        equipment_name: '',
        em_id: '',
        warranty_end_time: '',
        kehu_name: '',
        model_name: '',
        power: '',
        rname: '',
        wlw_cardNo: '',
        wlw_factoryNo: '',
        remarks: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      ocList: [],
      orList: [],
      ORid: [],
      EmList: [],
      user_role: {}
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    this.getID()
  },
  mounted () {

  },
  watch: {
    'addform.oc_id': {
      handler: function () {
       	// console.log(this.linkageID)
       	 this.linkageID(this.addform.oc_id)
      },
      deep: true
    },
    'editform.oc_id': {
      handler: function () {
       	// console.log(this)
       	 this.linkageID(this.editform.oc_id)
      },
      deep: true
    }
  },
  beforeUpdate () {
    this.ocList = this.$store.state.ocList
    this.ORid = this.$store.state.RLid
    // this.ERid = this.$store.state.ERid
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.editform = this.$common.forData2(item.row, this.editform)
        /* 质保时间（已废弃） */
        // this.editform.warranty_time = [item.row.warranty_start_time, item.row.warranty_end_time]
        this.editAdmin = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取客户区域列表
    getUserList (page, report) {
      const params = {
        page: page || this.Page.page,
        name: this.name,
        oc_id: this.ScId,
        or_id: typeof (this.SrId) === 'object' ? this.SrId.id : this.SrId,
        em_id: this.SeId
      }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'equipment/lists')
        return
      }
      this.$axios.post('equipment/lists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.warning(Data.message)
      }).catch((error) => this.$message.error(error))
    },
    // 添加
    addMin () {
      // console.log(this.addform.warranty_time)
      // var warrantyStartTime = this.addform.warranty_time[0]
      // var warrantyEndTime = this.addform.warranty_time[1]
      const params = this.$common.forData(this.addform)
      this.$axios.post('equipment/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.addAdmin = false
          var that = this
          // 清空表单
          this.$common.resetField('addform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => this.$message.error(error))
    },
    // 修改
    editMin () {
      // var warrantyStartTime = this.editform.warranty_time[0]
      // var warrantyEndTime = this.editform.warranty_time[1]
      const params = this.$common.forData(this.editform)
      this.$axios.post('equipment/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          this.$common.resetField('editform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => this.$message.error(error))
    },
    // 删除
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('equipment/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => this.$message.error(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取详情
    getDetail (scope) {
      // console.log(scope)
      this.DetailForm = this.$common.forData2(scope.row, this.DetailForm)
      this.Detail = true
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    // 获取客户信息ID
    getID () {
      // 获取设备类型
      this.$axios.post('equipment_model/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          this.EmList = data.data
        } else this.$message.warning(data.message)
      }).catch((err) => this.$message.error(err))
    },
    linkageID (ocid) {
      // 先清空再填入信息
      this.orList = []
      this.ORid.forEach(item1 => {
        if (item1.oc_id === ocid) this.orList.push(item1)
      })
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor{
    width: 100% !important;
  }
  .el-input.is-disabled::v-deep .el-input__inner,
  .el-select::v-deep .el-input.is-disabled .el-input__inner{
    color: #575757;
  }
  .search::v-deep .el-col{
    margin: 10px 0;
  }
</style>
